.sidebar {
  background: $first-theme-color;
  background: -moz-linear-gradient(-45deg, $first-theme-color 28%, $second-theme-color 99%);
  background: -webkit-linear-gradient(-45deg, $first-theme-color 28%, $second-theme-color 99%);
  background: linear-gradient(135deg, $first-theme-color 28%, $second-theme-color 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$first-theme-color, endColorstr=$second-theme-color, GradientType=1);
}

.sidebar-menu .sidebar-link.active::before {
  background: #ffffff !important;
}
