.simulator-credit{
  .bck{
    background-color: $first-theme-color!important;
  }
  .irs-single {
    background: none !important;
    color: black !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
    border-top-color: #fafbfc !important;
  }

  .irs--flat .irs-bar {
    background-color: $first-theme-color !important;
    height: 10px !important;
  }

  .irs--round .irs-bar {
    background-color: $first-theme-color !important;
    height: 10px !important;
  }

  .irs--round .irs-handle {
    background-color: $first-theme-color !important;
    border: 4px solid $first-theme-color !important;
  }

  .irs-line {
    height: 10px !important;
  }

  .irs-bar .irs-bar--single {
    height: 10px !important;
  }

  .irs--round .irs-handle {
    width: 26px !important;
    height: 26px !important;
  }

  .form-control {
    border: 0px solid #ced4da !important;
    border-bottom: 1px solid #ced4da !important;
  }

  .input-group-text {
    background: none !important;
    border: 0px solid #ced4da !important;
  }
}
