.style-form {
  background: #f2eeee;
  border-radius: 10px;
  padding: 30px;
}

.bouton-center {
  margin: auto;
}

.bloc-title-index {
  text-align: center;
  //background: #757575;
  //padding: 4px;
  font-size: 21px;
  //border-radius: 5px;
  color: black;
}

.title-content {
  margin-bottom: 25px;
}

.form-formation{
  margin-bottom:35px;
}


.dateJma{
 select{
   padding: 8px;
   border-radius: 0.25rem;
 }
}

#registration_pro_user_dateNaissance_day {
  width: 30%;
  margin-right: 3%;
}

#registration_pro_user_dateNaissance_month {
  width: 30%;
  margin-right: 1.5%;
  margin-left: 1.5%;
}

#registration_pro_user_dateNaissance_year {
  width: 30%;
  margin-left: 3%;
}

.shadow-light {
  -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
}

.err-active {
  color: #dc3545;
}

.has-error .form-control, .has-error .select2-dropdown, .has-error .select2-selection {
  background: #f2dede !important;
  border: 1px solid #ced4da;
}
