.p-60Lg30Xs {
  padding: 60px !important;
}

.p-60Lg0Xs {
  padding: 60px !important;
}

.p-60Lg10Xs {
  padding: 60px !important;
}

.pR-30Lg0Xs {
  padding-right: 30px !important;
}

.mL-20Lg50Xs {
  margin-left: 20px;
}

.mL30Lg0Xs {
  margin-left: 30px !important;
}

.mL30Lg15Xs {
  margin-left: 30px !important;
}

.mR0Lg15Xs {
  margin-right: 0px !important;
}

.hiddenLgBlockXS {
  display: none;
}

.hiddenXsBlockLg {
  display: block;
}

.noPlPr {
  padding-left: 0px;
  padding-right: 0px;
}

.shadowLightLgNoShadowXs {
  -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
}

.centrage-verticale-parent {
  display: flex;
}

.centrage-verticale-enfant {
  margin: auto;
}

no-mT {
  margin-top: 0px !important;
}

@media (max-width: 375px) {
  .mL-0Xs{
    margin-left:0px !important
  }
  .mR-5Xs {
    margin-right: 5px !important;
  }
  .mT-20Xs {
    margin-top: 20px!important;
  }
  .pT-35Xs {
    padding-top: 35px !important;
  }
  .pL-35Xs {
    padding-left: 35px !important;
  }
  .p-60Lg10Xs {
    padding: 10px !important;
  }
  .p-60Lg30Xs {
    padding: 30px !important;
  }

  .p-60Lg0Xs {
    padding: 0px !important;
  }

  .noMRXs {
    margin-right: 0px !important;
  }

  .mL-20Lg50Xs {
    margin-left: 50px !important;
  }

  .mL30Lg0Xs {
    margin-left: 0px !important;
  }

  .mL30Lg15Xs {
    margin-left: 15px !important;
  }

  .mR0Lg15Xs {
    margin-right: 15px !important;
  }

  .noMXs {
    margin: 0 !important;
  }

  .hiddenLgBlockXS {
    display: block;
  }

  .hiddenXsBlockLg {
    display: none;
  }

  .floatInheritXs {
    float: inherit !important;
  }

  .left-15Xs {
    left: -15px !important;
  }

  .left-50Xs {
    left: -50px !important;
  }

  .left-20Xs {
    left: -20px !important;
  }

  .left-95Xs {
    left: -95px !important;
  }

  .left-141Xs {
    left: -141px !important;
  }

  .left-187Xs {
    left: -187px !important;
  }

  .left-169Xs {
    left: -169px !important;
  }

  .left-214Xs {
    left: -214px !important;
  }

  .left-260Xs {
    left: -260px !important;
  }

  .left-307Xs {
    left: -307px !important;
  }

  .mL85Xs {
    left: 85px !important;
  }

  .mL120Xs {
    left: 120px !important;
  }
  .shadowLightLgNoShadowXs {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1);
  }
  .pR-30Lg0Xs {
    padding-right: 0px !important;
  }
}
