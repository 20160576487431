.alert-purple-alt { border-color: #694D9F;background: #694D9F;color: #fff; }
.alert-info-alt { border-color: #f0f0f0;background: #f0f0f0;color: #000000; }
.alert-danger-alt { border-color: #B63E5A;background: #FF3547;color: #fff; }
.alert-warning-alt { border-color: #F3F3EB;background: #FF8800;color: #fff; }
.alert-success-alt { border-color: #f0f0f0;background: #f0f0f0;color: #000000; }
.alert-info-alt > [data-notify="icon"] > img, .alert-danger-alt > [data-notify="icon"] > img, .alert-warning-alt > [data-notify="icon"] > img, .alert-success-alt > [data-notify="icon"] > img, .alert-purple-alt > [data-notify="icon"] > img {
  height: 30px;
  margin-right: 12px;
  margin-bottom: 10px;
}
.alert a {color: #B63E5A;}