table {
  &.dataTable {
    &.no-footer {
      border-bottom: 1px solid $border-color;
      margin-bottom: 20px;
    }
  }
}

.sorting_asc {
  &:focus {
    outline: none;
  }
}

.dataTables_wrapper {
  overflow: hidden;
  padding-bottom: 5px;

  .dataTables_length{
    color: $default-dark;
    float: left;
    cursor: pointer;

    @include to($breakpoint-sm) {
      text-align: left;
    }

    select {
      border: 1px solid $border-color;
      border-radius: 2px;
      box-shadow: none;
      height: 35px;
      font-size: 14px;
      padding: 5px;
      margin-left: 5px;
      margin-right: 5px;
      color: $default-text-color;
      transition: all 0.2s ease-in;
    }
  }

  .dataTables_filter {
    color: $default-dark;
    float: right;

    @include to($breakpoint-sm) {
      text-align: left;
    }

    input {
      border: 1px solid $border-color;
      border-radius: 2px;
      box-shadow: none;
      height: 35px;
      font-size: 14px;
      margin-left: 15px;
      padding: 5px;
      color: $default-text-color;
      transition: all 0.2s ease-in;
      min-width: 350px;
    }
  }

  .dataTables_info {
    color: $default-text-color;
    float: left;
  }

  .dataTables_processing {
    color: $default-dark;
  }

  .dataTables_paginate {
    color: $default-text-color;
    float: right;

    .paginate_button {
      color: $default-text-color !important;
      padding: 6px 12px;
      border-radius: 2px;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;
      text-decoration: none;
      cursor: pointer;

      &.next,
      &.previous,
      &.first,
      &.last {
        border-radius: 2px;
        text-decoration: none;

        &:hover,
        &:focus {
          color: #fff !important;
        }

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      &:hover {
        color: #fff !important;
        background: $first-theme-color;
      }

      &.current {
        color: #fff !important;
        background: $first-theme-color;

        &:hover {
          color: $default-white !important;
          background: $first-theme-color;
        }
      }
    }
  }

  .status {
    width: 5px;
    height: 5px;
  }
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  cursor: pointer;
  *cursor: hand;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right;
}


table.dataTable thead .sorting {
  background-image: url($images-path + "datatables/sort_both.png");
}
table.dataTable thead .sorting_asc {
  background-image: url($images-path + "datatables/sort_asc.png");
}
table.dataTable thead .sorting_desc {
  background-image: url($images-path + "datatables/sort_desc.png");
}
table.dataTable thead .sorting_asc_disabled {
  background-image: url($images-path + "datatables/sort_asc_disabled.png");
}
table.dataTable thead .sorting_desc_disabled {
  background-image: url($images-path + "datatables/sort_desc_disabled.png");
}
