.box-title-conseiller, .box-title-vous {
  font-size: 25px;
  position: relative;
  margin-top: -6px;
  top: 9px;
}

.progress-tracker--center .progress-text::before {
  border-bottom: 20px solid #eeeeee;
  margin-left: -25px;
}

.background-theme-degrade {
  background: $first-theme-color;
  background: -moz-linear-gradient(-45deg, $first-theme-color 28%, $second-theme-color 99%);
  background: -webkit-linear-gradient(-45deg, $first-theme-color 28%, $second-theme-color 99%);
  background: linear-gradient(135deg, $first-theme-color 28%, $second-theme-color 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$first-theme-color, endColorstr=$second-theme-color, GradientType=1);
}

.background-first-theme-color {
  background: $first-theme-color;
}

//.background-grey {
//  background: rgba(212, 212, 212, 0.39);
//}

.cptPieces {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $first-theme-color;
  color: white;
  z-index: 1;
  right: -12px;
  top: -12px;
}

.cptPiecesIndexProduct {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $first-theme-color;
  color: white;
  z-index: 1;
  right: 18px;
  top: 85px;
}

.cptPiecesIndexProductPro {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $first-theme-color;
  color: white;
  z-index: 1;
  right: -10px;
  top: -10px;
}

.cptPiecesOkIndexProductPro {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #28a745;
  color: white;
  z-index: 1;
  right: -10px;
  top: -10px;
}

.cptPiecesOkIndexProduct {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #28a745;
  color: white;
  z-index: 1;
  right: 18px;
  top: 85px;
}
