.float-label-control {
  position: relative;
  //margin-bottom: 3em;
  margin-top: 2em;
}

.float-label-control ::-webkit-input-placeholder {
  color: transparent;
}

.float-label-control :-moz-placeholder {
  color: transparent;
}

.float-label-control ::-moz-placeholder {
  color: transparent;
}

.float-label-control :-ms-input-placeholder {
  color: transparent;
}

.float-label-control input:-webkit-autofill,
.float-label-control textarea:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -moz-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
}

.float-label-control input, .float-label-control textarea, .float-label-control label {
  font-size: 1.3em;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.float-label-control input:focus,
.float-label-control textarea:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom-width: 2px;
  padding-bottom: 0;
}

.float-label-control textarea:focus {
  padding-bottom: 4px;
}

.float-label-control input, .float-label-control textarea {
  display: block;
  width: 100%; /*padding: 0.1em 0em 1px 0em;*/
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #aaa;
  outline: none;
  margin: 0px;
  background: none;
}

.float-label-control textarea {
  padding: 0.1em 0em 5px 0em;
}

.float-label-control label {
  position: absolute;
  font-weight: normal;
  top: -1.5em;
  left: 0.08em;
  color: #999;
  font-size: 0.85em;
  -moz-animation: float-labels 300ms none ease-out;
  -webkit-animation: float-labels 300ms none ease-out;
  -o-animation: float-labels 300ms none ease-out;
  -ms-animation: float-labels 300ms none ease-out;
  -khtml-animation: float-labels 300ms none ease-out;
  animation: float-labels 300ms none ease-out;
  animation-play-state: running !important;
  -webkit-animation-play-state: running !important;
}

.float-label-control input.empty + label,
.float-label-control textarea.empty + label {
  top: 0.1em;
  font-size: 1.5em;
  animation: none;
  -webkit-animation: none;
}

.float-label-control input:not(.empty) + label,
.float-label-control textarea:not(.empty) + label {
  z-index: 1;
}

.float-label-control input:not(.empty):focus + label,
.float-label-control textarea:not(.empty):focus + label {
  color: #aaaaaa;
}

@keyframes float-labels {
  0% {
    opacity: 1;
    color: #aaa;
    top: 0.1em;
    font-size: 1.5em;
  }
  20% {
    font-size: 1.5em;
    opacity: 0;
  }
  30% {
    top: 0.1em;
  }
  50% {
    opacity: 0;
    font-size: 0.85em;
  }
  100% {
    top: -1em;
    opacity: 1;
  }
}

@-webkit-keyframes float-labels {
  0% {
    opacity: 1;
    color: #aaa;
    top: 0.1em;
    font-size: 1.5em;
  }
  20% {
    font-size: 1.5em;
    opacity: 0;
  }
  30% {
    top: 0.1em;
  }
  50% {
    opacity: 0;
    font-size: 0.85em;
  }
  100% {
    top: -1em;
    opacity: 1;
  }
}

#needs-validation {
  .form-control {
    height: 60px !important;
  }

  label {
    text-transform: uppercase;
  }

  .requirements {
    position: absolute;
    padding-top: 5px;
    font-size: 15px;
    max-height: 0;
    //transition: 0.28s;
    overflow: hidden;
    color: red;
    font-style: italic;
  }

  > textarea,
  input,
  select {
    &:invalid:not(:focus):not(:placeholder-shown) {
      //background: pink;
      border-color: red;
      border-bottom-width: 1px;

      & ~ .requirements {
        max-height: 200px;
      }
    }
  }

  .radio-inline-imput {
    opacity: 0.4;
    z-index: 1;
    position: absolute;
    top: 0;
    cursor: pointer;
    height: 35px;
    width: 50px !important;
    margin: 0;
  }

  .radios label {
    padding-left: 100px;
    margin-bottom: 40px;
    font-weight: normal;
    cursor: pointer;
  }

  .radios label:last-of-type,
  .radios input:last-of-type {
    margin-left: 100px !important;
  }

  button[type="submit"],
  .btn-deault-d {
    cursor: pointer;
    display: block;
    width: 100%;
    margin: auto !important;
    //background: #41d873;
    //color: white;
    border: 0;
    padding: 10px;
    //font-size: 1.5em;
    text-transform: uppercase;
  }

  .valid-bg {
    > input {
      //background: url($images-path + "check.svg");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: right;
      border-color: #4fc32c;
      border-bottom-width: 2px;
    }

    > .err-active {
      display: none;
    }
  }

  .pink-bg {
    > input {
      //background: pink;
      border-color: red;
      border-bottom-width: 1px;
      max-height: 200px;
    }
  }
}

