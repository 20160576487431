.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  margin: 0 0.175rem 0.2em 0;
  padding: 0.5rem 0.75rem;
  border-color: #80bdff;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
  padding: 0.2rem 0.375rem 0 0.4rem;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0.3rem 0.3rem 0.3rem 0.75rem;
}

.select2, .select2-search {
  width: 100% !important;
}

li.select2-search > input {
  width: 100% !important;
}

.icheckbox_line {
  padding: 10px 20px 10px 20px;
}