.resultat-cc-partenaires {
  .card-header .fa {
    font-size: 2em;
    //transition: .3s transform ease-in-out;
  }

  .card-header .collapsed .fa {
    transform: rotate(90deg);
  }
}
