.fs4{
  font-size: 4px;
}
.fs6{
  font-size: 6px;
}
.fs8{
  font-size: 8px;
}
.fs10{
  font-size: 10px;
}
.fs11{
  font-size: 11px;
}
.fs12{
  font-size: 12px;
}
.fs14{
  font-size: 14px;
}
.fs15{
  font-size: 15px;
}
.fs16{
   font-size: 16px;
 }
.fs18{
  font-size: 18px;
}
.fs20{
  font-size: 20px;
}
.fs22{
  font-size: 22px;
}
.fs24{
  font-size: 24px;
}
.fs26{
  font-size: 26px;
}
.fs28{
  font-size: 28px;
}
.fs30{
  font-size: 30px;
}
@media (max-width: 375px) {
  .fs4Xs {
    font-size: 4px;
  }
  .fs6Xs {
    font-size: 6px;
  }
  .fs8Xs {
    font-size: 8px;
  }
  .fs10Xs {
    font-size: 10px;
  }
  .fs12Xs {
    font-size: 12px;
  }
  .fs14Xs {
    font-size: 14px;
  }
  .fs16Xs {
    font-size: 16px;
  }
  .fs18Xs {
    font-size: 18px;
  }
  .fs20Xs {
    font-size: 20px;
  }

  .lh1Xs{
    line-height:1;
  }
  .lh1-5Xs{
    line-height:1.5;
  }
  .lh2Xs{
    line-height:2;
  }
}
