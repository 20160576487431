.in_put_form {
  border-top: 1px solid #b1b1b1;
  border-right: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  border-left: 1px solid #b1b1b1;
}

.form-control:focus {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.form-control {
  border-radius: 5px !important;
}

.icheckbox_line {
  display: initial;
}

label {
  margin: 0;
}

.rac-add-entry {
  background: #efefef;
  padding-top: 15px;
  text-align: center;
}

.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

//.form-group input {
//  color: #b1b1b1;
//  font-size: 18px;
//  font-weight: bold;
//}
