a {
  color: $first-theme-color;
}

a:hover {
  color: $first-theme-color;
}

.width-timelapse {
  width: 89%;
}

.timeline-formation {
  height: 19px;
  border-style: solid;
  background: #efefef;
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 3px;
  border-left-width: 0;
}

.timeline-formation:hover {
  background: $first-theme-color;
}

.text-first-theme-color {
  color: $first-theme-color;
}

.bg-first-theme-color {
  background-color: $first-theme-color;
}

.text-second-theme-color {
  color: $second-theme-color;
}

.text-third-theme-color {
  color: $third-theme-color;
}

.text-fourth-theme-color {
  color: $fourth-theme-color;
}

.curseur-step {
  width: 100%;
  text-align: center;
  margin-top: -8px;
}

.stepSommaire {
  box-shadow: rgba(121, 121, 121, .35) 1px 10px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
}

.prev-step, .next-step, .title-step {
  height: 90px;
  color: white;
  background-color: $first-theme-color;
  margin: auto;
}

.prev-step, .next-step {
  width: 20%;
  font-size: 25px;
}

.title-step {
  width: 80%;
  font-size: 16px;
  border-right-style: solid;
  border-left-style: solid;
  border-width: 2px;
}

.margin-auto {
  margin: auto;
}

.prev-step {
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
}

.next-step {
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
}

.num-chap {
  background-color: white;
  color: $first-theme-color;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: auto;
  font-size: 23px;
  position: relative;
  top: 17px;
  -webkit-box-shadow: 0px 0px 23px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 23px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 23px -5px rgba(0, 0, 0, 0.75);
}

.margin-left-xs {
  margin-left: 5px;
}

.margin-right-xs {
  margin-right: 5px;
}

.margin-right-md {
  margin-right: 10px;
}

.margin-right-lg {
  margin-right: 20px;
}

.margin-left-1 {
  margin-left: 8.33333333%
}

.home-icon {
  height: 50px;
  width: 50px;
  background: $first-theme-color;
  color: white;
  border-radius: 50%;
  margin-top: -15px;
}

.step-parent-icon {
  height: 47px;
  width: 47px;
  background: $first-theme-color;
  color: white;
  border-radius: 50%;
  margin-top: -15px;
}

.center-home-icon {
  margin-left: 9px;
  margin-top: 10px;
}

.info-formation {
  background-color: #4a4a4a;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.info-top {
  margin-right: 30px;
}

.bold {
  font-weight: bold;
}

.btn-suivant a, .btn-retour a {
  background: $first-theme-color;
  padding: 10px;
  color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
}

.btn-debut-formation a {
  background: $first-theme-color;
  padding: 15px;
  color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
}

//.btn-home-formation{
//  margin-left: 78px;
//}

.next-step-parent {
  margin-left: -27px;
}

.center-num-step {
  margin-left: 18px;
  margin-top: 4px;
}

.center-num-step-parent {
  margin-left: 16px;
  margin-top: 4px;
}

.center-qcm {
  margin-left: 6px;
  margin-top: 12px;
}

.curseur-step-right {
  position: absolute;
  margin-left: -14px;
  margin-top: 6px;
}

.timeline-position {
  margin-left: -23px;
  margin-right: 4px;
}

.text-gris {
  color: $gris !important;
}

.text-gris-clair {
  color: $gris-clair !important;
}

.sidebar-menu li.dropdown .arrow {
  right: 10px !important;
}

.shadow-bloc {
  box-shadow: rgba(121, 121, 121, .35) 1px 10px 20px;
}

.border-radius-5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.border-radius-top-5 {
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.border-radius-top-left-5 {
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
}

.border-radius-top-right-5 {
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
}

.img-presentation {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-box-shadow: 0px 0px 10px 1px #cfcfcf;
  -webkit-box-shadow: 0px 0px 10px 1px #cfcfcf;
  -o-box-shadow: 0px 0px 10px 1px #cfcfcf;
  box-shadow: 0px 0px 10px 1px #cfcfcf;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=NaN, Strength=10);
  border-radius: 3px;
}

.div-zoom:hover {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.div-zoom {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=NaN, Strength=10);
  border-radius: 3px;
}

.img-presentation:hover {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.flag, .cup, .pencil {
  width: 25px;
  height: 25px;
}

.flag {
  background-color: $first-theme-color;
}

.pencil {
  background-color: $first-theme-color;
}

.cup {
  background-color: $first-theme-color;
}

.cercle-sommaire {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  background: white;
  text-align: center;
}

.hidden {
  display: none !important;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

.btn-reprendre {
  background: $first-theme-color;
  padding: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
}

.btn-terminee {
  background: #0b8232;
  padding: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
}

.btn-reprendre a, .btn-terminee a {
  color: #ffffff;
}

.btn-debuter {
  background: grey;
  padding: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
}

.btn-debuter a {
  color: #ffffff;
}

.avanceePoucentage{
  height: 3px;
  background: #4CAF50;
  width: 100%;
  position: absolute;
}

.btn-pd {
  background: $fourth-theme-color;
  padding: 10px;
  border-radius: 5px;
  color: white;
  -webkit-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.75);
}

.btn-pd:hover {
  background: $third-theme-color;
}

.btn-pd a{
  color:#ffffff;
}

.rcPro{
  -webkit-box-shadow: 0px 0px 25px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 25px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 25px -8px rgba(0,0,0,0.75);
  padding: 30px;
  border-radius: 5px;
}

.btn-rc-oui, .btn-rc-non{
  margin-right: 10px;
  margin-left: 10px;
}

.padding-40{
  padding: 40px;
}

.docs-juridiques{
  min-height: 315px;
  background: $first-theme-color;
}

.docs-juridiques a{
  color:white;
}

.qcm{
  margin-top: 25px;
  text-align: center;
  padding: 10px;
  font-size: 20px;
  background: $first-theme-color;
  font-weight: bold;
}

.qcm a{
  color:white;
}

@media (max-width: 375px) {
  .hidden-sp{
    display: none;
  }

  .content-formation-position{
    margin-bottom: -10px;
  }
}

@media (max-width: 1794px) {
  .width-timelapse{
    width: 84%
  }
}

@media (max-width: 1132px) {
  .width-timelapse{
    width: 83%
  }
}

@media (max-width: 1074px) {
  .width-timelapse{
    width: 82%
  }
}

@media (max-width: 1074px) {
  .width-timelapse{
    width: 82%
  }
}

@media (max-width: 922px) {
  .width-timelapse{
    width: 81%
  }
}

@media (max-width: 876px) {
  .width-timelapse{
    width: 80%
  }
}

@media (max-width: 832px) {
  .width-timelapse{
    width: 79%
  }
}

@media (max-width: 796px) {
  .width-timelapse{
    width: 78%
  }
}

@media (max-width: 760px) {
  .totale-timeline{
    display:none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $comfortaa;
}
h1{
  font-size: 26px;
  color: $first-theme-color;
}
h2{
  font-size: 20px;
}
h3{
  font-size: 17px;
  color:$first-theme-color
}

.btn-grey-plus:hover {
  background: $third-theme-color
}

.btn-grey:hover{
  background: #d0d0d0;
}

.breadcrumb{
  background-color: #ffffff !important;
}

.totalStepEloa{
  width: 350px;
  background: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  color:black;
  font-size: 20px;
  -webkit-box-shadow: 0px 0px 39px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 39px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 39px -9px rgba(0,0,0,0.75);
}

.stepEloa{
  background: $first-theme-color;
  padding: 15px;
  margin-top: -15px;
  margin-left: -15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 5px;
  color: white;
  width: 38px;
}

.stepEloaTitle{
  margin-left: 40px;
}

.schema-saas a{
  font-size:20px
}

.parent-triangle-h svg {
  width: 100%;
  height: 15px;
  vertical-align: bottom;
}

.parent-triangle-b svg {
  width: 100%;
  height: 30px;
  vertical-align: bottom;
}

.bloc-title-formation{
  background: $first-theme-color;
  padding: 10px;
}

.bloc-title-formation h3{
  color: #ffffff;
}

.bloc-formation{
  //border-style: solid;
  //border-width: 2px;
  //border-color: $first-theme-color;
  -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
}

.prix{
  font-size:30px;
}

.bloc-desciptif-formation{
  padding: 25px;
}

.stepParcoursFormation li{
  display:inline;
}

.bloc-formation{
  min-height:555px;
  border-radius:5px;
}

.bloc-formation h3{
  font-size : 16px;
}

.tpsFormation{
  font-size: 15px;
  font-style: italic;
}

/*Start Wizard*/
form#wizard-1 {
  width: 100%;

}
.bootstrapWizard {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%

}

.bootstrapWizard a:hover,.bootstrapWizard a:active,.bootstrapWizard a:focus {
  text-decoration: none
}

.bootstrapWizard li {
  display: block;
  float: left;
  width: 11%;
  text-align: center;
  padding-left: 0;
  height:100px;
}
/*ligne*/
.bootstrapWizard li.complete:before {
  border-top: 5px solid $first-theme-color;
  content: "";
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: 11px;
  right: 1px;
  width: 100%;
  z-index: 1

}
.bootstrapWizard li:before {
  border-top: 5px solid $first-theme-color;
  content: "";
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: 11px;
  right: 1px;
  width: 100%;
  z-index: 1

}
.bootstrapWizard li:first-child:before {
  left: 50%;
  max-width: 50%
}

.bootstrapWizard li:last-child:before {
  max-width: 50%;
  width: 50%

}

.bootstrapWizard li.complete .step {
  background: $first-theme-color;
  padding: 11px 16px;
  border: 5px solid $first-theme-color;
}

.bootstrapWizard li .step i {
  font-size: 10px;
  font-weight: 400;
  position: relative;
  top: -1.5px
}

.bootstrapWizard li .step {
  background: $first-theme-color;
  color: #fff;
  display: inline;
  font-size: 15px;
  font-weight: 700;
  line-height: 12px;
  padding: 7px 16px;
  border: 3px solid transparent;
  border-radius: 50%;
  line-height: normal;
  position: relative;
  text-align: center;
  z-index: 2;
  transition: all .1s linear 0s
}

.bootstrapWizard li.active .step,.bootstrapWizard li.active.complete .step {
  background: $first-theme-color;
  color: #fff;
  font-weight: 700;
  padding: 7px 16px;
  font-size: 15px;
  border-radius: 50%;
  border: 3px solid #DA6400;
}

.bootstrapWizard li.complete .title,.bootstrapWizard li.active .title {
  color: $first-theme-color;
}

.bootstrapWizard li .title {
  color: #bfbfbf;
  display: block;
  font-size: 13px;
  line-height: 15px;
  max-width: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
  top: 20px;
  word-wrap: break-word;
  z-index: 104
}

.wizard-actions {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%
}

.wizard-actions li {
  display: inline
}

.tab-content.transparent {
  background-color: transparent
}

/*End Wizard*/
