.icone{
  text-align: center;
}

.icone .my_false {
   filter: invert(50%);
   color: #5e5e6a;
   background: transparent;
 }

.icone .my_true {
  filter: invert(0%);
  color: #830130;
  background: transparent;
}

.icone img:hover {
  filter: invert(0%);
  color: #830130;
  background: transparent;
}

.icone img {
  cursor: pointer;
  max-height: 50px;
  position: relative;
  bottom: 0;
  filter: invert(50%);
  color: #5e5e6a;
  background: transparent;
}

@media (min-width: 320px){
  .icone img {
    max-height: 50px;
    bottom: 0;
  }
}

@media (min-width: 500px) {
  .icone img {
    max-height: 80px;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .icone img {
    max-height: 80px;
    bottom: 0;
  }
}

.my_title{
  font-size: 20px;
  color: $first-theme-color;
}


