.colorCaretStepInfo{
  color: $first-theme-color;
}

.bloc-step-lp {
  h1 {
    font-size: 26px;
    margin: 0 0 10px;
  }
  h2 {
    font-size: 20px;
    margin-top: 40px;
  }
  h3{
    font-size: 14px;
    font-weight: bold;
    margin-top: 25px;
    color: $first-theme-color;
  }
  h4{
    font-size: 14px;
    font-weight: bold;
    font-family: $baseFontFamily;
  }
  .header-content {
    padding: 25px 15px;

    .bloc-left{
      display: table-cell;
      width: 70%;
      padding: 0 5px 0 0;
      @media (max-width: 991px) {
        border-bottom: 2px solid $default-white;
        border-right: none;
        padding: 0 0 10px 0;
        display: block;
        width: 95%;
        margin: auto;

      }
    }
    .bloc-right{
      margin: 35px 0;
      background-color: $first-theme-color;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      color: $default-white;
      display: table-cell;
      width: 30%;
      vertical-align: middle;
      @media (max-width: 991px) {
        display: block;
        width: 95%;
        margin: auto;
      }
    }

    .zero {
      .zero-chiffre {
        font-size: 2em;
        font-weight: bold;

      }
      .phrase {
        text-align: center;
        font-size: 1.2em;
        padding: 0 10px;
      }
      .rond {
        font-size: 30px;
        display: inline-block;
        background: $first-theme-color;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 80px;

        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5) inset,
        0px 2px 2px 0px rgba(255, 255, 255, 0.1);
      }
    }

    .gagnez {
      @media (max-width: 992px) {
        margin-top: 15px;
      }
    }
  }

  .bloc-formulaire {
    background-color: #fafbfb;
    padding: 15px 0;

    .circle {
      margin: 10px;
      width: 60px;
      height: 60px;
      text-align: center;
      border-radius: 60px;
      line-height: 60px;
      color: $default-white;
      font-size: 20px;
    }

    .etape {
      display: table;
    }
    .text {
      display: table-cell;
      vertical-align: middle;
      font-size: 20px;
    }

    .bloc-etapes {
      border-bottom: 1px solid #e2e2e2;
      text-align: center;
    }
    .bloc-etape {
      display: inline-block;
    }

    .btn-next {
      float: right;
      background-color: $first-theme-color;
      color: $default-white;
    }
    .btn-next:hover {
      background-color: $second-theme-color;
    }
  }


  @media (max-width: 450px) {
    .responsive-table-line td:before {
      content: attr(data-title);
    }
    .responsive-table-line table,
    .responsive-table-line thead,
    .responsive-table-line tbody,
    .responsive-table-line th,
    .responsive-table-line td,
    .responsive-table-line tr {
      display: block;
    }

    .responsive-table-line thead tr {
      display: none;
    }
    .responsive-table-line td {
      position: relative;
      border: 0px solid transparent;
      padding-left: 50% !important;
      white-space: normal;
      text-align: right;
    }

    .responsive-table-line td:before {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 45%;
      height: 100%;
      white-space: nowrap;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      text-align: left;
      background-color: #f8f8f8;
      padding: 2px 15px 2px 2px;
    }
  }


  .img-emprunteur, .img-habitation,.img-garantie-revente,.img-garantie-impaye{
    width:320px;
    float: right;
    -moz-box-shadow: 1px 1px 5px 0px #656565;
    -webkit-box-shadow: 1px 1px 5px 0px #656565;
    -o-box-shadow: 1px 1px 5px 0px #656565;
    box-shadow: 1px 1px 5px 0px #656565;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=135, Strength=10);
    margin: 10px;
    @media (max-width: 550px) {
      float: none;
      margin:15px auto 15px;
    }
  }
  .table-garantie-revente{
    th, td{
      display: table-cell;
      vertical-align: middle;
      font-weight: normal;
    }
  }

  .bloc-guide{
    h3{
      margin-top: 5px;
    }
    .btn-guide{
      background-color: $first-theme-color;
      color: $default-white;
    }
  }

  .bloc-taux {
    .bloc {
      max-width: 300px;
      .btn-guide{
        background-color: $first-theme-color;
        color: $default-white;
      }
      h3 {
        font-size: 17px;
        color: $first-theme-color;
        text-align: center;
      }
    }
  }
  .barometre {
    max-width: 950px;
    margin: 15px auto;

    th {
      background-color: $first-theme-color;
      color: $default-white;
      vertical-align: middle;
    }
    .align-vertical{
      vertical-align: middle;
    }

    @media (max-width: 450px) {
      .responsive-table-line td:before {
        content: attr(data-title);
      }
      .responsive-table-line table,
      .responsive-table-line thead,
      .responsive-table-line tbody,
      .responsive-table-line th,
      .responsive-table-line td,
      .responsive-table-line tr {
        display: block;
      }

      .responsive-table-line thead tr {
        display: none;
      }
      .responsive-table-line td {
        position: relative;
        border: 0px solid transparent;
        padding-left: 50% !important;
        white-space: normal;
        text-align: right;
      }

      .responsive-table-line td:before {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 45%;
        height: 100%;
        white-space: nowrap;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        text-align: left;
        background-color: #f8f8f8;
        padding: 2px 15px 2px 2px;
      }
    }
  }

  .btn-rouge{
    background-color: $first-theme-color;
    color: $default-white;
  }
  .btn-rouge:hover {
    background-color: $second-theme-color;
  }

  .iradio_line{
    width: 100%;
  }

  #formulaire_rachat_credits_form_nouveauProjet .iradio_line{
    width: 45%;
  }
  .bloc-lien{
    width: 750px;
    margin: auto;
    padding: 10px;
    -moz-box-shadow: 5px 5px 5px 0px #cfcfcf;
    -webkit-box-shadow: 5px 5px 5px 0px #cfcfcf;
    -o-box-shadow: 5px 5px 5px 0px #cfcfcf;
    box-shadow: 5px 5px 5px 0px #cfcfcf;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=5);
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    .block-inline{
      width: 360px;
      margin: 0 !important;
    }
    @media (max-width: 768px) {
      width: 95%;
      .block-inline{
        display: block;
      }
    }

  }
}

.bloc-etape{
  cursor: pointer;
  .etape{
    .circle{
      background-color: $gris-moyen;
    }
    .text{
      color: $gris-moyen;
    }
  }
  .active{
    .circle{
      background-color: $first-theme-color;
    }
    .text{
      color: $first-theme-color;
    }
  }

}

/*.form-inline {
  display: inline;
}

.form-inline-block {
  display: inline-block;
}*/

/*#startDate input, #endDate input{
  color: #000 !important;
}*/

/*.title-form{
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 40px;
}*/

/*.collection-item-actions {
  float: right;
  margin: 2px 2px 0 0;

  > button {
    margin-left: 2px;
  }
}*/
