@media (max-width: 575.98px) {
  .col-p-detail{
    width: 100% !important;
  }
}
@media (min-width: 575.98px) {
  .col-p-detail{
    width: 100% !important;
  }
}

@media (min-width: 767.98px) {
  .col-p-detail{
    width: 50% !important;
  }
}

@media (min-width: 991.98px) {
  .col-p-detail{
    width: 50% !important;
  }
}

@media (min-width: 1199.98px) {
  .col-p-detail {
    width: 33.33333333% !important;
  }
}

@media (min-width: 1500px) {
  .col-p-detail{
    width: 25% !important;
  }
}
