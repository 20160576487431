.column-side-left-app {
  .column-side-left-nav {
    background: $default-white;
    position: fixed;
    border-right: 1px solid $border-color;
    float: left;
    width: 300px;
    //transition: all 0.3s ease-in-out;

    @include to($breakpoint-md) {
      z-index: 1;
      left: -300px;
    }
  }

  .column-side-left-wrapper {
    margin: 0;
    padding: 0;
    overflow: auto;
    min-height: 100%;
    //transition: all 0.3s ease-in-out;

    @include to($breakpoint-md) {
      position: absolute;
      left: 0;
      width: 100%;
      overflow-x: hidden;
    }

    @include from($breakpoint-md) {
      margin-left: 300px;
    }

    .email-list {
      position: relative;
      padding: 0;
      width: 100%;
      overflow-y: hidden;
      background-color: $default-white;
      height: calc(100vh - #{$header-height});

      @include to($breakpoint-md) {
        max-height: calc(100vh - 65px);
      }

      @include from($breakpoint-md) {
        width: 40%;
        border-right: 1px solid $border-color;
        float: left;
      }
    }

    .column-side-left-content {
      float: left;
      width: 60%;
      position: relative;
      padding: 0;
      background-color: $default-white;
      // min-height: calc(100vh - #{$header-height});

      &.no-inbox-view {
        width: 100%;
      }

      @include to($breakpoint-md) {
        //position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        //transition: all 0.3s ease-in-out;
        max-height: calc(100vh - #{$header-height});
        overflow-y: scroll;

        &.open {
          left: 0;
        }
      }
    }

    .column-side-left-compose {
      position: relative;
      .column-side-left-body {
        padding: 30px 20px;

      }
    }
  }

  &.side-active {
    .column-side-left-nav {
      @include to($breakpoint-md) {
        left: 0;
      }
    }

    .column-side-left-wrapper {
      @include to($breakpoint-md) {
        left: 300px;
      }
    }
  }
}
