.payment-page{
  width: 600px;
}

.block-heading {
  padding-top: 30px !important;
}

.payment-form.dark {
  background-color: #fafbfb !important;
}

.svg-icon, .svg-icon-error {
  width: 4em;
  height: 4em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: $theme-success;
}

.svg-icon-error path,
.svg-icon-error polygon,
.svg-icon-error rect {
  fill: $theme-danger;
}

.svg-icon-error circle {
  stroke: $theme-danger;
  stroke-width: 1;
}

.svg-icon circle {
  stroke: $theme-success;
  stroke-width: 1;
}
.profile-main-loader{
  z-index: 9000 !important;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 150px;
  height:150px;
}
.profile-main-loader .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: $first-theme-color;
  }
  40% {
    stroke: $first-theme-color;
  }
  66% {
    stroke: $first-theme-color;
  }
  80%, 90% {
    stroke: $first-theme-color;
  }
}
@keyframes color {
  0% {
    stroke: $first-theme-color;
  }
  40% {
    stroke: $first-theme-color;
  }
  66% {
    stroke: $first-theme-color;
  }
  80%, 90% {
    stroke: $first-theme-color;
  }
}

.payment-form {
  padding-bottom: 50px;
  font-family: 'Montserrat', sans-serif;
}

.payment-form.dark {
  background-color: #f6f6f6;
}

.payment-form .content {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: white;
}

.payment-form .block-heading {
  padding-top: 130px;
  margin-bottom: 40px;
  text-align: center;
}

.payment-form .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.payment-form.dark .block-heading p {
  opacity: 0.8;
}

.payment-form .block-heading h1,
.payment-form .block-heading h2,
.payment-form .block-heading h3 {
  margin-bottom: 1.2rem;
  color: $first-theme-color;
}

.payment-form form {
  border-top: 2px solid $first-theme-color;
  /*box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);*/
  background-color: #ffffff;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

.payment-form .title {
  font-size: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8em;
  font-weight: 600;
  padding-bottom: 8px;
}

.payment-form .products {
  background-color: #FFFFFF;
  padding: 25px;
}

.payment-form .products .item {
  margin-bottom: 1em;
}

.payment-form .products .item-name {
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .item-description {
  font-size: 0.8em;
  opacity: 0.6;
}

.payment-form .products .item p {
  margin-bottom: 0.2em;
}

.payment-form .products .price {
  float: right;
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .total {
  padding-top: 10px;
  font-weight: 600;
  line-height: 1;
}

.payment-form .card-details {
  padding: 25px 25px 15px;
}

.payment-form .card-details label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #79818a;
  text-transform: uppercase;
}

.payment-form .card-details button {
  margin-top: 0.6em;
  padding: 12px 0;
  font-weight: 600;
}

.payment-form .date-separator {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

@media (min-width: 576px) {
  .payment-form .title {
    font-size: 1.2em;
  }

  .payment-form .products {
    padding: 40px;
  }

  .payment-form .products .item-name {
    font-size: 1em;
  }

  .payment-form .products .price {
    font-size: 1em;
  }

  .payment-form .card-details {
    padding: 40px 40px 30px;
  }

  .payment-form .card-details button {
    margin-top: 2em;
  }
}

.global-checkout {
  -ms-flex-positive: 1;
  flex-grow: 1;
  /*padding-top: 150px;
  margin-bottom: 150px;*/
  /*background: #f9fafb;*/
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 62.5%;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;

  .container,
  .container-fluid,
  .container-lg,
  .container-wide,
  .container-xl {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
  }

  .container,
  .container-lg {
    max-width: 1040px;
  }

  .container-wide,
  .container-xl {
    max-width: 1160px;
  }

  .common-SuperTitle {
    font-weight: 300;
    font-size: 45px;
    line-height: 60px;
    color: #32325d;
    letter-spacing: -.01em;
  }

  @media (min-width: 670px) {
    .common-SuperTitle {
      font-size: 50px;
      line-height: 70px;
    }
  }

  .common-IntroText {
    font-weight: 400;
    font-size: 21px;
    line-height: 31px;
    color: #525f7f;
  }

  @media (min-width: 670px) {
    .common-IntroText {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .common-BodyText {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #6b7c93;
  }

  .common-Link {
    color: #6772e5;
    font-weight: 500;
    //transition: color 0.1s ease;
    cursor: pointer;
  }

  .common-Link:hover {
    color: #32325d;
  }

  .common-Link:active {
    color: #000;
  }

  .common-Link--arrow:after {
    font: normal 18px StripeIcons;
    content: "\2192";
    padding-left: 5px;
  }

  .common-Button {
    white-space: nowrap;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: #6772e5;
    text-decoration: none;
    //transition: all 0.15s ease;
  }

  .common-Button:hover {
    color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .common-Button:active {
    color: #555abf;
    background-color: #f6f9fc;
    transform: translateY(1px);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .common-Button--default {
    color: #fff;
    background: #6772e5;
  }

  .common-Button--default:hover {
    color: #fff;
    background-color: #7795f8;
  }

  .common-Button--default:active {
    color: #e6ebf1;
    background-color: #555abf;
  }

  .common-Button--dark {
    color: #fff;
    background: #32325d;
  }

  .common-Button--dark:hover {
    color: #fff;
    background-color: #43458b;
  }

  .common-Button--dark:active {
    color: #e6ebf1;
    background-color: #32325d;
  }

  .common-Button--disabled {
    color: #fff;
    background: #aab7c4;
    pointer-events: none;
  }

  .common-ButtonIcon {
    display: inline;
    margin: 0 5px 0 0;
    position: relative;
  }

  .common-ButtonGroup {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -10px;
  }

  .common-ButtonGroup .common-Button {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 10px;
  }

  /** Page-specific styles */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(1turn);
    }
  }

  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }

  /*body {
    overflow-x: hidden;
    background-color: #f6f9fc;
  }*/

  main {
    position: relative;
    display: block;
    z-index: 1;
  }

  .stripes {
    position: absolute;
    width: 100%;
    transform: skewY(-12deg);
    height: 950px;
    top: -350px;
    background: linear-gradient(180deg, #e6ebf1 350px, rgba(230, 235, 241, 0));
  }

  .stripes .stripe {
    position: absolute;
    height: 190px;
  }

  .stripes .s1 {
    height: 380px;
    top: 0;
    left: 0;
    width: 24%;
    background: linear-gradient(90deg, #e6ebf1, rgba(230, 235, 241, 0));
  }

  .stripes .s2 {
    top: 380px;
    left: 4%;
    width: 35%;
    background: linear-gradient(
                    90deg,
                    hsla(0, 0%, 100%, 0.65),
                    hsla(0, 0%, 100%, 0)
    );
  }

  .stripes .s3 {
    top: 380px;
    right: 0;
    width: 38%;
    background: linear-gradient(90deg, #e4e9f0, rgba(228, 233, 240, 0));
  }

  main > .container-lg {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    max-width: 600px;
    padding: -10px 20px 110px;
  }

  main > .container-lg .cell {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    -ms-flex: auto;
    flex: auto;
    min-width: 100%;
    min-height: 300px;
    padding: 0 40px;
  }

  main > .container-lg .cell + .cell {
    margin-top: 70px;
  }

  main > .container-lg .cell.intro {
    padding: 0;
  }

  @media (min-width: 670px) {
    main > .container-lg .cell.intro {
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }

    .optionList {
      margin-left: 13px;
    }
  }

  main > .container-lg .cell.intro > * {
    width: 100%;
    max-width: 700px;
  }

  main > .container-lg .cell.intro .common-IntroText {
    margin-top: 10px;
  }

  main > .container-lg .cell.intro .common-BodyText {
    margin-top: 15px;
  }

  main > .container-lg .cell.intro .common-ButtonGroup {
    width: auto;
    margin-top: 20px;
  }

  main > .container-lg .content-checkout-order {
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    //padding: 80px 0px;
    padding: 15px 0 15px 0;
    margin-left: -20px;
    margin-right: -20px;
  }

  @media (min-width: 670px) {
    main > .container-lg .content-checkout-order {
      //padding: 80px;
      padding: 0px 0px 40px 0px;
    }
  }

  main > .container-lg .content-checkout-order.submitted form,
  main > .container-lg .content-checkout-order.submitting form {
    opacity: 0;
  //  transform: scale(0.9);
    pointer-events: none;
  }

  main > .container-lg .content-checkout-order.submitted .success,
  main > .container-lg .content-checkout-order.submitting .success {
    pointer-events: all;
  }

  main > .container-lg .content-checkout-order.submitting .success .icon {
    opacity: 1;
  }

  main > .container-lg .content-checkout-order.submitted .success > * {
    opacity: 1;
   // transform: none !important;
  }



  main > .container-lg .content-checkout-order.submitted .success .icon .border,
  main > .container-lg .content-checkout-order.submitted .success .icon .checkmark {
    opacity: 1;
    stroke-dashoffset: 0 !important;
  }

  main > .container-lg .content-checkout-order * {
    /*margin: 10px;
    padding: 10px;*/
  }

  main > .container-lg .content-checkout-order .caption {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    padding: 15px 10px 0;
    color: #aab7c4;
    font-family: Roboto, "Open Sans", "Segoe UI", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  main > .container-lg .content-checkout-order .caption * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  main > .container-lg .content-checkout-order .caption .no-charge {
    color: #cfd7df;
    margin-right: 10px;
  }

  main > .container-lg .content-checkout-order .caption a.source {
    text-align: right;
    color: inherit;
    //transition: color 0.1s ease-in-out;
    margin-left: 10px;
  }

  main > .container-lg .content-checkout-order .caption a.source:hover {
    color: #6772e5;
  }

  main > .container-lg .content-checkout-order .caption a.source:active {
    color: #43458b;
  }

  main > .container-lg .content-checkout-order .caption a.source svg {
    margin-right: 10px;
  }

  main > .container-lg .content-checkout-order .caption a.source svg path {
    fill: currentColor;
  }

  main > .container-lg .content-checkout-order form {
    position: relative;
    width: 100%;
    max-width: 600px;
    //transition-property: opacity, transform;
    //transition-duration: 0.35s;
   // transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    padding-top: 25px;
  }

  main > .container-lg .content-checkout-order form input::-webkit-input-placeholder {
    opacity: 1;
  }

  main > .container-lg .content-checkout-order form input::-moz-placeholder {
    opacity: 1;
  }

  main > .container-lg .content-checkout-order form input:-ms-input-placeholder {
    opacity: 1;
  }

  main > .container-lg .content-checkout-order .error {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 100%;
    margin-top: 20px;
    left: 0;
    padding: 0 15px;
    font-size: 13px !important;
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  main > .container-lg .content-checkout-order .error.visible {
    opacity: 1;
    transform: none;
  }

  main > .container-lg .content-checkout-order .error .message {
    font-size: inherit;
  }

  main > .container-lg .content-checkout-order .error svg {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: -1px;
    margin-right: 10px;
  }

  main > .container-lg .content-checkout-order .success {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 10px;
    text-align: center;
    pointer-events: none;
    overflow: hidden;
  }

  @media (min-width: 670px) {
    main > .container-lg .content-checkout-order .success {
      padding: 40px;
    }
  }

  main > .container-lg .content-checkout-order .success > * {
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    transform: translateY(0px);
  }

  main > .container-lg .content-checkout-order .success .icon {
    margin: 15px 0 30px;
   // transform: translateY(70px) scale(0.75);
  }

  main > .container-lg .content-checkout-order .success .icon svg {
   // will-change: transform;
  }

  main > .container-lg .content-checkout-order .success .icon .border {
    stroke-dasharray: 251;
    stroke-dashoffset: 62.75;
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    animation: spin 1s linear infinite;
  }

  main > .container-lg .content-checkout-order .success .icon .checkmark {
    stroke-dasharray: 60;
    stroke-dashoffset: 60;
    transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s;
  }

  main > .container-lg .content-checkout-order .success .title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  main > .container-lg .content-checkout-order .success .message {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
  }

  main > .container-lg .content-checkout-order .success .message span {
    font-size: inherit;
  }

  main > .container-lg .content-checkout-order .success .reset:active {
    transition-duration: 0.15s;
    transition-delay: 0s;
    opacity: 0.65;
  }

  main > .container-lg .content-checkout-order .success .reset svg {
    will-change: transform;
  }

  footer {
    position: relative;
    max-width: 750px;
    padding: 50px 20px;
    margin: 0 auto;
  }

  .optionList {
    margin: 6px 0;
  }

  .optionList li {
    display: inline-block;
    margin-right: 13px;
  }

  .optionList a {
    color: #aab7c4;
    transition: color 0.1s ease-in-out;
    cursor: pointer;
    font-size: 15px;
    line-height: 26px;
  }

  .optionList a.selected {
    color: #6772e5;
    font-weight: 600;
  }

  .optionList a:hover {
    color: #32325d;
  }

  .optionList a.selected:hover {
    cursor: default;
    color: #6772e5;
  }

  .block-order {
    background-color: #fff;
  }

  .block-order * {
    font-family: Source Code Pro, Consolas, Menlo, monospace;
    font-size: 18px;
    font-weight: 500;
  }

  .block-order .row {
    display: -ms-flexbox;
    display: flex;
    margin: 0 35px 10px;
  }

  .block-order .field {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 10px;
  }

  .block-order .field.half-width {
    width: 45%;
  }

  .block-order .field.quarter-width {
    width: calc(25% - 10px);
  }

  .block-order .baseline {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #cfd7df;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .block-order label {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 8px;
    color: #cfd7df;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .block-order .input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
  }

  .block-order .input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .block-order .input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .block-order .input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .block-order .input.StripeElement {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
  }

  .block-order .input.focused,
  .block-order .input:not(.empty) {
    opacity: 1;
  }

  .block-order .input.focused::-webkit-input-placeholder,
  .block-order .input:not(.empty)::-webkit-input-placeholder {
    color: #cfd7df;
  }

  .block-order .input.focused::-moz-placeholder,
  .block-order .input:not(.empty)::-moz-placeholder {
    color: #cfd7df;
  }

  .block-order .input.focused:-ms-input-placeholder,
  .block-order .input:not(.empty):-ms-input-placeholder {
    color: #cfd7df;
  }

  .block-order .input.focused + label,
  .block-order .input:not(.empty) + label {
    color: #aab7c4;
    transform: scale(0.85) translateY(-25px);
    cursor: default;
  }

  .block-order .input.focused + label {
    color: $first-theme-color;
  }

  .block-order .input.invalid + label {
    color: #ffa27b;
  }

  .block-order .input.focused + label + .baseline {
    background-color: $first-theme-color;
  }

  .block-order .input.focused.invalid + label + .baseline {
    background-color: $theme-danger;
  }

  .block-order input, .block-order button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  .block-order input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .block-order .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .block-order input, .block-order button {
    -webkit-animation: 1ms void-animation-out;
  }

  .block-order button {
    display: block;
    //width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: $first-theme-color;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .block-order input:active {
    //background-color: $first-theme-color;
  }

  .block-order .error svg {
    margin-top: 0 !important;
  }

  .block-order .error svg .base {
    fill: $theme-danger;
  }

  .block-order .error svg .glyph {
    fill: #fff;
  }

  .block-order .error .message {
    color: $theme-danger;
  }

  .block-order .success .icon .border {
    stroke: $first-theme-color;
  }

  .block-order .success .icon .checkmark {
    stroke: $first-theme-color;
  }

  .block-order .success .title {
    color: #32325d;
    font-size: 18px !important;
  }

  .block-order .success .message {
    color: #8898aa;
    font-size: 13px !important;
  }

  .block-order .success .reset path {
    fill: $first-theme-color;
  }
}
