.carre img {
  height: 125px;
  width: 125px;
}

.carre:hover {
  cursor: pointer;
}

.carre img {
  height: 125px;
  width: 125px;
  background: $first-theme-color;
  border: solid 25px #EFEEEE;
}

#tableau-picto .text {
  font-weight: bold;
  color: $second-theme-color;
  padding: 5px;
}
