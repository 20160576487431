.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  background-color: $first-theme-color;
  border-color: $first-theme-color;
  color: white;
}

.pagination > li > a,
.pagination > li > span{
  color: $first-theme-color;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: $first-theme-color;
}

.page-item.active .page-link {
  background-color: $first-theme-color;
  border-color: $first-theme-color;
}

.pagination {
  display: inline-flex;
  padding-bottom:25px;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a:hover:not(.active) {background-color: #ddd;}
