@media (max-width: 700px) {
  .has-float-label .form-control:placeholder-shown:not(:focus) + * {
    font-size: 14px !important;
    margin-top: 4px;
  }
}

.has-float-label label {
  font-weight: unset;
  top: -5px !important;
  background: #fafbfb;
}

.has-float-label .form-control:placeholder-shown:not(:focus) + * {
  top: 0.3em !important;
  font-size: 20px;
}

.has-float-label > span::after {
  background: transparent !important;
}

.has-float-label label::after, .has-float-label > span::after {
  background: transparent !important;
}
