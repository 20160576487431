/*.iradio_line {
  display: inline-block;
  margin: 5px;
  background: $gris-moyen;
  width: 45%;
  text-align: center;
}
.iradio_line.checked {
  background-color: $first-theme-color;
}

.icheckbox_line {
  display: inline-block;
  margin: 5px;
  background: $gris-moyen;
  width: 100%;
  text-align: center;
}
.icheckbox_line.checked {
  background-color: $first-theme-color;
}

.iradio_line.has-error{
  //background: $state-danger-text;
}*/

.icheckbox_line,
.iradio_line {
  position: relative;
  display: block;
  margin: 0;
  padding: 5px 15px 5px 38px;
  font-size: 13px;
  line-height: 17px;
  color: #fff;
  background: $first-theme-color;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
}
.icheckbox_line .icheck_line-icon,
.iradio_line .icheck_line-icon {
  position: absolute;
  top: 50%;
  left: 13px;
  width: 13px;
  height: 11px;
  margin: -5px 0 0 0;
  padding: 0;
  overflow: hidden;
  //background: url(line.png) no-repeat;
  border: none;
}
.icheckbox_line.hover,
.icheckbox_line.checked.hover,
.iradio_line.hover {
  background: #444;
}
.icheckbox_line.checked,
.iradio_line.checked {
  background: #4c0005;
}
.icheckbox_line.checked .icheck_line-icon,
.iradio_line.checked .icheck_line-icon {
  background-position: -15px 0;
}
.icheckbox_line.disabled,
.iradio_line.disabled {
  background: #ccc;
  cursor: default;
}
.icheckbox_line.disabled .icheck_line-icon,
.iradio_line.disabled .icheck_line-icon {
  background-position: -30px 0;
}
.icheckbox_line.checked.disabled,
.iradio_line.checked.disabled {
  background: #ccc;
}
.icheckbox_line.checked.disabled .icheck_line-icon,
.iradio_line.checked.disabled .icheck_line-icon {
  background-position: -45px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_line .icheck_line-icon,
  .iradio_line .icheck_line-icon {
    //background-image: url($images-path + 'line@2x.png');
    -webkit-background-size: 60px 13px;
    background-size: 60px 13px;
  }
}

.icheckbox_line {
  text-align: center;
}
