.progress-tracker--vertical {
  .progress-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    width: 78px;
    height: 78px;
    padding-bottom: 0px;
    color: #fff;
    font-weight: 400;
    border: 2px solid $gris-moyen;
    border-radius: 50%;
    transition: background-color, border-color;
    transition-duration: 0.3s;
  }

  .progress-step::after {
    z-index: 10 !important;
    right: auto;
    top: 13px;
    left: 37px;
    width: 4px;
    height: 100%;
  }

  .progress-text {
    padding-left: 10px;
    padding-top: 7px;
    //margin-left: 100px;
    //-moz-box-shadow: 5px 5px 40px 0px #cfcfcf;
    //-webkit-box-shadow: 5px 5px 40px 0px #cfcfcf;
    //-o-box-shadow: 5px 5px 40px 0px #cfcfcf;
    //box-shadow: 5px 5px 40px 0px #cfcfcf;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=40);
  }
  .progress-text::before {
    position: absolute;
    top: 12%;
    margin-left: -50px;
    width: 0;
    height: 0;
    content: '';
    border-right: 20px solid $default-white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
  }
  .progress-step:not(:last-child) .progress-text {
    margin-bottom: 40px;
    padding-bottom: 10px;
  }

  .bouton {
    text-align: center;
    margin: 10px 0;
    bottom: 25px;
    .btn {
      width: 210px;
      border: none;
      color: $default-white;
      background-color: $rouge-lp;
    }
  }
}

.progress-tracker--center {
  .progress-step:not(:last-child)::after {
    content: '';
    display: block;
    position: absolute;
    top: 35px;
    width: 100%;
    z-index: 10 !important;
    height: 4px;
    transition: background-color 0.3s;
  }

  .progress-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 20;
    width: 78px;
    height: 78px;
    padding-bottom: 0px;
    color: #fff;
    font-weight: 400;
    border: 2px solid $gris-moyen;
    border-radius: 50%;
    transition: background-color, border-color;
    transition-duration: 0.3s;
  }

  @media (max-width: 399px) {
    .progress-tracker-mobile {
      overflow-x: auto;
    }
    .progress-tracker-mobile .progress-tracker {
      min-width: 200%;
    }
  }

  .progress-text {
    //background:white;
    height: 220px;
    //width: 350px;
    padding:30px;
    margin: 20px auto;
    margin-top:-10px;
    //-moz-box-shadow: 5px 5px 40px 0px #cfcfcf;
    //-webkit-box-shadow: 5px 5px 40px 0px #cfcfcf;
    //-o-box-shadow: 5px 5px 40px 0px #cfcfcf;
    //box-shadow: 5px 5px 40px 0px #cfcfcf;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=40);
    @media (max-width: 1580px) {
      width: 210px;
      height: 350px;
    }
    @media (max-width: 1199px) {
      width: 100%;
    }
    .bouton {
      text-align: center;
      margin: 10px 0;
      position: absolute;
      bottom: 25px;
      .btn {
        @media (max-width: 1580px) {
          width: 190px;
          font-size: 13px;
        }
        @media (max-width: 1199px) {
          width: 190px;
          font-size: 12px;
        }
        width: 210px;
        border: none;
        color: $default-white;
        background-color: $rouge-lp;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .progress-text::before {
    position: absolute;
    top: 59px;
    margin-left: -20px;
    width: 0;
    height: 0;
    content: '';
    border-right: 20px solid transparent;
    border-top: 20px solid transparent;
    border-bottom: 20px solid $default-white;
    border-left: 20px solid transparent;
  }

}

.progress-step {
  .progress-marker {
    .number {
      border-radius: 50%;
      background: $first-theme-color;
      color: $default-white;
      height: 21px;
      width: 21px;
      position: absolute;
      text-align: center;
      line-height: 21px;
      bottom: 65px;
      left: 27px;
    }
  }
}

.progress-step:hover .progress-marker {
  background-color: $default-white;
}

.progress-step {
  .progress-marker {
    background-color: $default-white;
  }
}

.progress-step.is-active {
  :hover .progress-marker {
    background-color: $gris;
  }
  .progress-marker {
    background-color: $gris;
  }
}

.progress-step.is-complete {
  :hover .progress-marker {
    background-color: $gris-moyen;
  }
  .progress-marker {
    background-color: $gris-moyen;
  }
}

.sprite-compte-pret-immo {
  background-repeat: no-repeat;
  display: block;
}

.sprite-compte-pret-immo-buildings {
  width: 44px;
  height: 38px;
  background-position: -4px -4px;
}

.sprite-compte-pret-immo-check {
  width: 50px;
  height: 38px;
  background-position: -4px -50px;
}

.sprite-compte-pret-immo-paper {
  width: 29px;
  height: 38px;
  background-position: -56px -4px;
}

.sprite-compte-pret-immo-pen-and-card {
  width: 39px;
  height: 38px;
  background-position: -93px -4px;
}
