.bg-third-theme-color {
  background: $third-theme-color;
}

.bg-rouge-fonce {
  background: $second-theme-color;
}

.bg-rouge {
  background: $first-theme-color;
}

.bg-gris-middle {
  background: $gris-middle;
}

.bg-fourth-theme-color {
  background: $fourth-theme-color;
}

.text-third-theme-color {
  color: $third-theme-color;
}
