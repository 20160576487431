.d-inline-block {
  width: 100%;
}

.tooltip-inner {
  background-color: $first-theme-color !important;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before{
  border-top-color: $first-theme-color !important;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $first-theme-color !important;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: $first-theme-color !important;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: $first-theme-color !important;
}
