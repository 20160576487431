label .invalid-feedback{
  font-size: 50% !important;
  margin-top: 13px;
}

label .invalid-feedback .d-block{
  display: none !important;
}

.has-float-label label{
  height: 8px;
}