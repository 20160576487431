.bloc-question{
  background: #f1f1f1;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  //-webkit-box-shadow: 0px 0px 24px -6px rgba(0,0,0,0.75);
  //-moz-box-shadow: 0px 0px 24px -6px rgba(0,0,0,0.75);
  //box-shadow: 0px 0px 24px -6px rgba(0,0,0,0.75);
}

.cercle-question{
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  background: $first-theme-color;
  text-align: center;
}

.step-qcm{
  color: white;
  padding-top: 22px;
  margin-top: -70px;
  position: absolute;
  right: -35px;
}

.cercle-score{
  width: 100px;
  height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: $first-theme-color;
  text-align: center;
}

.txt-score{
  font-size: 20px;
  padding-top: 30px;
  font-weight: bold;
}
