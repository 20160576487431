.stepClient {
  width: 78px;
  height: 78px;
  background-color: #b1b0b1;
}

.divCircle {
  border-radius: 50%;
}

.numberStep {
  background-color: $first-theme-color;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 47%;
  top: -10px;
}

.stepBackground {
  background-color: #eeeeee;
}

.caretPosition {
  position: absolute;
  top: 53px;
  left: 43%;
}

.colorCaret {
  color: #eeeeee;
}

.heightStep {
  height: 275px;
}

.buttonStepClient{
  position: absolute;
  bottom: 0;
  width: 100%;
  left:0;
}
