a {
  color: #72777a;
}

a:hover {
  color: black;
}

.msg-box-client-ico {
  background: $first-theme-color;
}

.msg-box-client-content {
  padding: 10px;
}

.border-left-5 {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.send-proposition {
  padding: 37px;
  background: white;
}

.bg-gris {
  background: #eee
}

.margin-20 {
  margin: 20px
}

.del-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tb-title-bloc-msg, .tb-title-bloc-rdv {
  background: $first-theme-color;
  color: white;
  padding: 10px;
  font-size: 20px;
}

.tb-content-bloc-msg, .tb-content-bloc-rdv {
  padding: 20px;
}

.titleTypeModuleFicheClient {
  background: $first-theme-color;
  color: white;
  padding: 10px;
  font-size: 20px;
}

.del-padding {
  padding-left: 0px;
  padding-right: 0px
}

//Prospect Pret Immo

.title-info-client {
  background: $first-theme-color;
  color: white;
  padding: 10px;
  font-size: 20px;
}

.content-info-client {
  padding: 20px;
}

.content-info-client-projet {
  padding-left: 20px;
  padding-right: 20px;
}

.del-padding {
  padding-left: 0px;
  padding-right: 0px
}

.bg-gris {
  background: #eee
}

.titleEmprunteur {
  font-size: 27px;
  position: relative;
  top: 10px;
}

.hover-table:hover {
  background: blue;
}

.emprunteur-hat {
  font-size: 25px;
  position: relative;
  top: 9px;
}

.msg-box-client-ico {
  background: $first-theme-color;
}

.msg-box-client-content {
  padding: 10px;
}

.border-left-5 {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.bg-gris {
  background: #eee
}

.padding-5 {
  padding: 5px;
}

.border-radius-bottom-5 {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.btn-grey {
  background: #eee;
}

.btn-grey-plus {
  background: $gris-moyen;
}

.btn-grey-plus:hover {
  background: $gris-middle !important;
}

.tb-curseur{
  margin-top:20px;
}

.dLeft35md{
  left:35px
}

.client-bloc-left {
  width: 10%;
}

.client-bloc-center {
  width: 70%;
}

.client-bloc-right {
  width: 10%;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.tb-resume-client {
  /*padding:20px*/
}

.tb-sample-info {
  margin-left: 20px;
}

/*.paddding-top-padding-bottom-10{*/
/*padding-top: 10px;*/
/*padding-bottom: 10px;*/
/*}*/
.margin-top-10 {
  margin-top: 10px;
}

.preview-1, .preview-2, .preview-3, .preview-4 {
  padding: 20px;
}

.margin-left-30 {
  margin-left: 30px
}

.margin-top-30 {
  margin-top: 30px;
}

.tb-preview-client-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
}

.bloc-commentaire {
  position: relative;
  min-height: 50px;
}

.icon-pen,.icon-pen-admin {
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}

@media (max-width: 1560px) {
  .txBtnRd {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .dLeft35md{
    left:0px
  }

  .mB-25xs-{
    margin-bottom:25px;
  }
}

@media (max-width: 375px) {
  .main-content{
    width: 100%;
  }
  .breadcrumb{
    margin-top:55px;
  }
  .iradio_line{
    width:100%;
  }
}
