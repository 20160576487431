$font-path: '../../../fonts';
@font-face {
  font-family: 'comfortaabold';
  src: url($font-path +'/comfortaa-bold-webfont.eot');
  src: url($font-path +'/comfortaa-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url($font-path +'/comfortaa-bold-webfont.woff2') format('woff2'),
  url($font-path +'/comfortaa-bold-webfont.woff') format('woff'),
  url($font-path +'/comfortaa-bold-webfont.ttf') format('truetype'),
  url($font-path +'/comfortaa-bold-webfont.svg#comfortaabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'comfortaaregular';
  src: url($font-path +'/comfortaa-regular-webfont.eot');
  src: url($font-path +'/comfortaa-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url($font-path +'/comfortaa-regular-webfont.woff2') format('woff2'),
  url($font-path +'/comfortaa-regular-webfont.woff') format('woff'),
  url($font-path +'/comfortaa-regular-webfont.ttf') format('truetype'),
  url($font-path +'/comfortaa-regular-webfont.svg#comfortaaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-size-16{
  font-size: 16px;
}

.font-size-18{
  font-size: 18px;
}

.font-size-20{
  font-size: 20px;
}

.font-size-24{
  font-size: 24px;
}
